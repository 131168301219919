// Imports => React
import React, {
  useRef,
  useEffect,
  useState,
  useCallback,
  useMemo,
  memo,
} from 'react';
import clsx from 'clsx';

// Imports => Utilities
import { AcIsSet } from '@utils';

// Imports => Atoms
import AcCard from '@atoms/ac-card/ac-card.web';

const _CLASSES = {
  MAIN: 'ac-expandable',
  EXPANDED: 'ac-expandable--expanded',
  LIMITED: 'ac-expandable--limited',
  TITLE: 'ac-expandable__title',
  CONTENT: {
    MAIN: 'ac-expandable__content',
    CHILDREN: 'ac-expandable__content__children',
  },
};

const AcExpandable = ({
  id,
  title,
  children,
  open = false,
  limited = false,
  callback,
  className,
}) => {
  const [expanded, setExpanded] = useState(open);
  const [height, setHeight] = useState(0);

  const $content = useRef(null);

  useEffect(() => {
    setExpanded(open);
  }, [open]);

  useEffect(() => {
    calculateContentHeight();
  }, [expanded]);

  const handleToggle = (event) => {
    const state = expanded;
    setExpanded(!state);
    calculateContentHeight();
    if (callback) callback(!state, id);
  };

  const calculateContentHeight = useCallback(() => {
    if (!AcIsSet($content) || !AcIsSet($content.current)) return;

    let height = 20;

    if ($content.current && $content.current.childNodes) {
      const children = $content.current.childNodes;
      const collection = children;
      const len = collection.length;
      let n = 0;

      for (n; n < len; n++) {
        const node = collection[n];
        height += node.scrollHeight;
      }
    }

    if (limited) height = height > 300 ? 300 : height;

    setHeight(height);
  }, [$content, limited]);

  const getContentClassNames = useMemo(() => {
    return clsx(_CLASSES.CONTENT.MAIN);
  }, []);

  const getChildrenClassNames = useMemo(() => {
    return clsx(_CLASSES.CONTENT.CHILDREN);
  }, []);

  const getTitleClassNames = useMemo(() => {
    return clsx(_CLASSES.TITLE);
  }, []);

  const getCardClassNames = useMemo(() => {
    return clsx(
      _CLASSES.MAIN,
      expanded && _CLASSES.EXPANDED,
      limited && _CLASSES.LIMITED,
      className
    );
  }, [expanded, limited, className]);

  const getBodyInlineStyles = useMemo(() => {
    return {
      height: expanded ? `${height / 10 || 0}rem` : '0rem',
    };
  }, [expanded, height]);

  return (
    <AcCard
      className={getCardClassNames}
      theme={'transparent'}
      hoverAnimation={false}
    >
      <div className={getTitleClassNames} onClick={handleToggle}>
        {title}
      </div>
      <div
        ref={$content}
        style={getBodyInlineStyles}
        className={getContentClassNames}
      >
        <div className={getChildrenClassNames}>{children}</div>
      </div>
    </AcCard>
  );
};

export default memo(AcExpandable);
