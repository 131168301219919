// Imports => React
import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { withStore } from '@stores';
import { observer } from 'mobx-react-lite';
import clsx from 'clsx';

// Imports => Data
import { COUNTRIES } from '@data/countries.data';

// Imports => Constants
import {
  DATETIME_FORMATS,
  KEYS,
  PERMISSIONS,
  THEMES,
  TYPES,
  VARIANTS,
  VISUALS,
} from '@constants';

// Imports => Utilities
import { AcIsSet, AcIsEmptyString, AcFormatDate } from '@utils';

// Imports => Hooks
import { usePermissions, useFormActions } from '@hooks';

// Imports => Atoms
import { AcContainer, AcRow, AcColumn } from '@atoms/ac-grid';
import AcHeading from '@atoms/ac-heading/ac-heading.web';
import AcRichContent from '@atoms/ac-rich-content/ac-rich-content.web';
import AcTextInput from '@atoms/ac-text-input/ac-text-input.web';
import AcDatepickerInput from '@atoms/ac-datepicker-input/ac-datepicker-input.web';
import AcSelectBox from '@atoms/ac-select-box/ac-select-box.web';
import AcCheckbox from '@atoms/ac-checkbox/ac-checkbox.web';
import AcButton from '@atoms/ac-button/ac-button.web';
import AcLoader from '@atoms/ac-loader/ac-loader.web';

const _CLASSES = {
  MAIN: 'ac-edit-contract-modal',
  CONTENT: 'ac-edit-contract-modal__content',
};

let delay = null;

const AcEditContractModal = ({
  store: { ui, contracts, companies },
  data,
  submit,
  callback,
}) => {
  const { can, cannot } = usePermissions();

  let raw_fields = {
    name: data.name,
    date_start: data.date_start,
    date_end: data.date_end,
  };
  let raw_errors = {
    name: null,
    date_start: null,
    date_end: null,
  };

  const [fields, setFields] = useState(raw_fields);
  const [errors, setErrors] = useState(raw_errors);

  const {
    hasErrors,
    handleInputChange,
    handleInputValidation,
  } = useFormActions({
    fields,
    setFields,
    errors,
    setErrors,
  });

  const handleCancel = async (event) => {
    if (event && event.preventDefault) event.preventDefault();
    await ui.setValue(KEYS.MODAL, KEYS.VISIBLE, false);
  };

  const handleSubmit = (event) => {
    if (event && event.preventDefault) event.preventDefault();

    if (submit) {
      const date_start = AcFormatDate(
        fields.date_start,
        null,
        DATETIME_FORMATS.RAW_DATE
      );
      const date_end = AcFormatDate(
        fields.date_end,
        null,
        DATETIME_FORMATS.RAW_DATE
      );

      const object = {
        ...fields,
        date_start,
        date_end,
      };

      submit(data.id, object).then(() =>
        ui.setValue(KEYS.MODAL, KEYS.VISIBLE, false)
      );
    }
  };

  const getContentClassNames = useMemo(() => {
    return clsx([_CLASSES.CONTENT]);
  }, []);

  const getStyleClassNames = useMemo(() => {
    return clsx([_CLASSES.MAIN]);
  }, []);

  const getNameInputOptions = useMemo(() => {
    return {
      type: TYPES.TEXT,
      label: 'Name',
      name: 'name',
      value: fields.name,
      callback: handleInputChange,
      validation: handleInputValidation,
      focus: true,
    };
  }, [fields, fields.name]);

  const getCompanyInputOptions = useMemo(() => {
    return {
      type: TYPES.TEXT,
      label: 'Company',
      name: 'company',
      value: (data.company && data.company.name) || '-',
      disabled: true,
    };
  }, [data, data.name]);

  const getStartDateInputOptions = useMemo(() => {
    const start = new Date(fields.date_start);
    const end = new Date(fields.date_end);

    return {
      type: TYPES.DATE,
      label: 'Start date',
      placeholder: 'dd-mm-yyyy',
      name: 'date_start',
      value: start,
      start: start,
      end: end,
      range: 'start',
      callback: handleInputChange,
      validation: handleInputValidation,
    };
  }, [fields, fields.date_start, fields.date_end]);

  const getEndDateInputOptions = useMemo(() => {
    const start = new Date(fields.date_start);
    const end = new Date(fields.date_end);

    return {
      type: TYPES.DATE,
      label: 'End date',
      placeholder: 'dd-mm-yyyy',
      name: 'date_end',
      value: end,
      min: start,
      start: start,
      end: end,
      range: 'end',
      callback: handleInputChange,
      validation: handleInputValidation,
    };
  }, [fields, fields.date_end, fields.date_start]);

  const getCancelButtonOptions = useMemo(() => {
    return {
      type: TYPES.BUTTON,
      theme: THEMES.OMEGA,
      variant: VARIANTS.TEXT,
      title: 'Cancel',
      callback: handleCancel,
    };
  });

  const getSubmitButtonOptions = useMemo(() => {
    return {
      type: TYPES.SUBMIT,
      theme: THEMES.ALPHA,
      disabled: hasErrors,
      title: 'Save changes',
      callback: handleSubmit,
    };
  }, [fields, errors, hasErrors]);

  return (
    <div className={getStyleClassNames}>
      <div className={getContentClassNames}>
        {fields && (
          <form method={'post'} onSubmit={handleSubmit}>
            <AcContainer fluid>
              <AcRow>
                <AcColumn>
                  <AcTextInput {...getNameInputOptions} />
                </AcColumn>
              </AcRow>

              <AcRow>
                <AcColumn>
                  <AcTextInput {...getCompanyInputOptions} />
                </AcColumn>
              </AcRow>

              <AcRow>
                <AcColumn>
                  <AcHeading
                    rank={5}
                    className={'h-margin-top-10 h-margin-bottom-0'}
                  >
                    Contract duration
                  </AcHeading>
                </AcColumn>
              </AcRow>

              <AcRow className={'h-margin-top-0'}>
                <AcColumn xs={12} sm={6}>
                  <AcDatepickerInput {...getStartDateInputOptions} />
                </AcColumn>

                <AcColumn xs={12} sm={6}>
                  <AcDatepickerInput {...getEndDateInputOptions} />
                </AcColumn>
              </AcRow>

              <AcRow className={'h-margin-top-20'}>
                <AcColumn
                  xxs={12}
                  xs={7}
                  sm={6}
                  className={'h-text--align-left h-flex-v-align-center'}
                >
                  <AcButton {...getCancelButtonOptions}>
                    <span>Cancel</span>
                  </AcButton>
                </AcColumn>

                <AcColumn
                  xxs={12}
                  xs={5}
                  sm={6}
                  className={'h-text--align-right'}
                >
                  <AcButton {...getSubmitButtonOptions}>
                    <span>Save changes</span>
                  </AcButton>
                </AcColumn>
              </AcRow>
            </AcContainer>
          </form>
        )}
      </div>

      {contracts.is_busy && <AcLoader loading={true} cover />}
    </div>
  );
};

export default withStore(observer(AcEditContractModal));
