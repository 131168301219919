// Imports => React
import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { withStore } from '@stores';
import { observer } from 'mobx-react-lite';
import clsx from 'clsx';

// Imports => Data
import { COUNTRIES } from '@data/countries.data';

// Imports => Constants
import {
  KEYS,
  PERMISSIONS,
  ROLES,
  THEMES,
  TYPES,
  VARIANTS,
  VISUALS,
} from '@constants';

// Imports => Hooks
import { usePermissions, useFormActions } from '@hooks';

// Imports => Atoms
import { AcContainer, AcRow, AcColumn } from '@atoms/ac-grid';
import AcRichContent from '@atoms/ac-rich-content/ac-rich-content.web';
import AcTextInput from '@atoms/ac-text-input/ac-text-input.web';
import AcSelectBox from '@atoms/ac-select-box/ac-select-box.web';
import AcButton from '@atoms/ac-button/ac-button.web';
import AcLoader from '@atoms/ac-loader/ac-loader.web';

const _CLASSES = {
  MAIN: 'ac-edit-user-modal',
  CONTENT: 'ac-edit-user-modal__content',
};

let delay = null;

const AcEditUserModal = ({
  store: { ui, users, companies },
  data,
  submit,
  callback,
}) => {
  const { can, cannot } = usePermissions();
  let raw_fields = {
    name: data.name,
    email: data.email,
    phone: data.phone,
    company_id: data.company_id,
    roles: data.roles,
  };
  let raw_errors = {
    name: null,
    email: null,
    phone: null,
    company_id: null,
    roles: null,
  };

  const [fields, setFields] = useState(raw_fields);
  const [errors, setErrors] = useState(raw_errors);

  const {
    hasErrors,
    handleInputChange,
    handleInputValidation,
  } = useFormActions({
    fields,
    setFields,
    errors,
    setErrors,
  });
  
  console.log('edit user, has errros:', hasErrors)

  useEffect(() => {
    if (can(PERMISSIONS.USER.UPDATE_COMPANY))
      companies.list({ per_page: 999, options: true });
  }, []);

  const handleCancel = async (event) => {
    if (event && event.preventDefault) event.preventDefault();
    await ui.setValue(KEYS.MODAL, KEYS.VISIBLE, false);
  };

  const handleSubmit = (event) => {
    if (event && event.preventDefault) event.preventDefault();

    if (submit)
      submit(data, fields).then(() =>
        ui.setValue(KEYS.MODAL, KEYS.VISIBLE, false)
      );
  };

  const getContentClassNames = useMemo(() => {
    return clsx([_CLASSES.CONTENT]);
  }, []);

  const getStyleClassNames = useMemo(() => {
    return clsx([_CLASSES.MAIN]);
  }, []);

  const getNameInputOptions = useMemo(() => {
    return {
      type: TYPES.TEXT,
      label: 'Name',
      name: 'name',
      value: fields.name,
      callback: handleInputChange,
      validation: handleInputValidation,
      focus: true,
    };
  }, [fields, fields.name]);

  const getEmailInputOptions = useMemo(() => {
    return {
      type: TYPES.EMAIL,
      label: 'Email address',
      placeholder: 'name@domain.com',
      name: 'email',
      value: fields.email,
      callback: handleInputChange,
      validation: handleInputValidation,
    };
  }, [fields, fields.email]);

  const getPhoneInputOptions = useMemo(() => {
    return {
      type: TYPES.PHONE,
      label: 'Phone number',
      placeholder: 'Phone number',
      name: 'phone',
      value: fields.phone,
      callback: handleInputChange,
      validation: handleInputValidation,
      required:false,
      disabled: cannot(PERMISSIONS.USER.UPDATE_PHONE),
    };
  }, [fields, fields.phone]);

  const getCompanySelectOptions = useMemo(() => {
    const { current_companies_list } = companies;
    let options = [];

    if (current_companies_list) {
      const len = current_companies_list.length;
      let n = 0;

      for (n; n < len; n++) {
        const item = current_companies_list[n];

        const object = {
          name: item.name,
          value: item.id,
        };

        options.push(object);
      }
    }

    return {
      type: TYPES.TEXT,
      label: 'Company',
      name: 'company_id',
      placeholder: 'Select a company',
      value: fields.company_id,
      callback: handleInputChange,
      validation: handleInputValidation,
      maxOptions: 6,
      options,
      disabled: cannot(PERMISSIONS.USER.UPDATE_COMPANY),
    };
  }, [companies.current_companies_list, fields, fields.company_id]);

  const getCancelButtonOptions = useMemo(() => {
    return {
      type: TYPES.BUTTON,
      theme: THEMES.OMEGA,
      variant: VARIANTS.TEXT,
      title: 'Cancel',
      callback: handleCancel,
    };
  });

  const getSubmitButtonOptions = useMemo(() => {
    return {
      type: TYPES.SUBMIT,
      theme: THEMES.ALPHA,
      disabled: hasErrors,
      title: 'Save changes',
      callback: handleSubmit,
    };
  }, [fields, errors, hasErrors]);

  return (
    <div className={getStyleClassNames}>
      <div className={getContentClassNames}>
        {fields && (
          <form method={'post'} onSubmit={handleSubmit}>
            <AcContainer fluid>
              <AcRow>
                <AcColumn>
                  <AcTextInput {...getNameInputOptions} />
                </AcColumn>
              </AcRow>

              <AcRow>
                <AcColumn>
                  <AcTextInput {...getEmailInputOptions} />
                </AcColumn>
              </AcRow>
              <AcRow>
                <AcColumn>
                  <AcTextInput {...getPhoneInputOptions} />
                </AcColumn>
              </AcRow>

              <AcRow>
                <AcColumn>
                  <AcSelectBox {...getCompanySelectOptions} />
                </AcColumn>
              </AcRow>

              <AcRow className={'h-margin-top-20'}>
                <AcColumn
                  xxs={12}
                  xs={7}
                  sm={6}
                  className={'h-text--align-left h-flex-v-align-center'}
                >
                  <AcButton {...getCancelButtonOptions}>
                    <span>Cancel</span>
                  </AcButton>
                </AcColumn>

                <AcColumn
                  xxs={12}
                  xs={5}
                  sm={6}
                  className={'h-text--align-right'}
                >
                  <AcButton {...getSubmitButtonOptions}>
                    <span>Save changes</span>
                  </AcButton>
                </AcColumn>
              </AcRow>
            </AcContainer>
          </form>
        )}
      </div>

      {users.is_busy && <AcLoader loading={true} cover />}
    </div>
  );
};

export default withStore(observer(AcEditUserModal));
