// Imports => React
import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { withStore } from '@stores';
import { observer } from 'mobx-react-lite';
import clsx from 'clsx';

// Imports => Constants
import {
  KEYS,
  PERMISSIONS,
  THEMES,
  TYPES,
  VARIANTS,
  VISUALS,
} from '@constants';

// Imports => Hooks
import { usePermissions, useFormActions } from '@hooks';

// Imports => Atoms
import { AcContainer, AcRow, AcColumn } from '@atoms/ac-grid';
import AcRichContent from '@atoms/ac-rich-content/ac-rich-content.web';
import AcTextInput from '@atoms/ac-text-input/ac-text-input.web';
import AcSelectBox from '@atoms/ac-select-box/ac-select-box.web';
import AcButton from '@atoms/ac-button/ac-button.web';
import AcLoader from '@atoms/ac-loader/ac-loader.web';

const _CLASSES = {
  MAIN: 'ac-edit-profile-modal',
  CONTENT: 'ac-edit-profile-modal__content',
};

let delay = null;

const AcEditUserModal = ({
  store: { ui, profile, companies },
  data,
  submit,
  callback,
}) => {
  const { can, cannot } = usePermissions();

  let raw_fields = {
    name: data.name,
    email: data.email,
    phone: data.phone,
  };
  let raw_errors = {
    name: null,
    email: null,
    phone: null,
  };

  const [fields, setFields] = useState(raw_fields);
  const [errors, setErrors] = useState(raw_errors);

  const {
    hasErrors,
    handleInputChange,
    handleInputValidation,
  } = useFormActions({
    fields,
    setFields,
    errors,
    setErrors,
  });

  useEffect(() => {}, []);

  const handleCancel = async (event) => {
    if (event && event.preventDefault) event.preventDefault();
    await ui.setValue(KEYS.MODAL, KEYS.VISIBLE, false);
  };

  const handleSubmit = (event) => {
    if (event && event.preventDefault) event.preventDefault();

    if (submit)
      submit(fields).then(() => ui.setValue(KEYS.MODAL, KEYS.VISIBLE, false));
  };

  const getContentClassNames = useMemo(() => {
    return clsx([_CLASSES.CONTENT]);
  }, []);

  const getStyleClassNames = useMemo(() => {
    return clsx([_CLASSES.MAIN]);
  }, []);

  const getNameInputOptions = useMemo(() => {
    return {
      type: TYPES.TEXT,
      label: 'Name',
      name: 'name',
      value: fields.name,
      callback: handleInputChange,
      validation: handleInputValidation,
      focus: true,
    };
  }, [fields, fields.name]);

  const getEmailInputOptions = useMemo(() => {
    return {
      type: TYPES.EMAIL,
      label: 'Email address',
      placeholder: 'name@domain.com',
      name: 'email',
      value: fields.email,
      callback: handleInputChange,
      validation: handleInputValidation,
    };
  }, [fields, fields.email]);

  const getPhoneInputOptions = useMemo(() => {
    return {
      type: TYPES.PHONE,
      label: 'Phone number',
      placeholder: 'Phone number',
      name: 'phone',
      value: fields.phone,
      callback: handleInputChange,
      validation: handleInputValidation,
    };
  }, [fields, fields.phone]);

  const getCompanyInputOptions = useMemo(() => {
    return {
      type: TYPES.TEXT,
      label: 'Company',
      name: 'company_id',
      value: data.company && data.company.name,
      disabled: true,
    };
  }, [data, data.company]);

  const getCancelButtonOptions = useMemo(() => {
    return {
      type: TYPES.BUTTON,
      theme: THEMES.OMEGA,
      variant: VARIANTS.TEXT,
      title: 'Cancel',
      callback: handleCancel,
    };
  });

  const getSubmitButtonOptions = useMemo(() => {
    return {
      type: TYPES.SUBMIT,
      theme: THEMES.ALPHA,
      disabled: hasErrors,
      title: 'Save changes',
      callback: handleSubmit,
    };
  }, [fields, errors, hasErrors]);

  return (
    <div className={getStyleClassNames}>
      <div className={getContentClassNames}>
        {fields && (
          <form method={'post'} onSubmit={handleSubmit}>
            <AcContainer fluid>
              <AcRow>
                <AcColumn>
                  <AcTextInput {...getNameInputOptions} />
                </AcColumn>
              </AcRow>

              <AcRow>
                <AcColumn>
                  <AcTextInput {...getEmailInputOptions} />
                </AcColumn>
              </AcRow>

              <AcRow>
                <AcColumn>
                  <AcTextInput {...getPhoneInputOptions} />
                </AcColumn>
              </AcRow>

              <AcRow>
                <AcColumn>
                  <AcTextInput {...getCompanyInputOptions} />
                </AcColumn>
              </AcRow>

              <AcRow className={'h-margin-top-20'}>
                <AcColumn
                  xxs={12}
                  xs={7}
                  sm={6}
                  className={'h-text--align-left h-flex-v-align-center'}
                >
                  <AcButton {...getCancelButtonOptions}>
                    <span>Cancel</span>
                  </AcButton>
                </AcColumn>

                <AcColumn
                  xxs={12}
                  xs={5}
                  sm={6}
                  className={'h-text--align-right'}
                >
                  <AcButton {...getSubmitButtonOptions}>
                    <span>Save changes</span>
                  </AcButton>
                </AcColumn>
              </AcRow>
            </AcContainer>
          </form>
        )}
      </div>

      {profile.is_busy && <AcLoader loading={true} cover />}
    </div>
  );
};

export default withStore(observer(AcEditUserModal));
