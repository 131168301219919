// Imports => React
import React, { useEffect, useState, useMemo } from 'react';
import { withStore } from '@stores';
import { observer } from 'mobx-react-lite';
import clsx from 'clsx';

// Imports => Constants
import { KEYS, THEMES, TYPES, VARIANTS, VISUALS } from '@constants';

// Imports => Hooks
import { useFormActions } from '@hooks';

// Imports => Atoms
import { AcContainer, AcRow, AcColumn } from '@atoms/ac-grid';
import AcRichContent from '@atoms/ac-rich-content/ac-rich-content.web';
import AcTextInput from '@atoms/ac-text-input/ac-text-input.web';
import AcSelectBox from '@atoms/ac-select-box/ac-select-box.web';
import AcButton from '@atoms/ac-button/ac-button.web';
import AcLoader from '@atoms/ac-loader/ac-loader.web';

const _CLASSES = {
	MAIN: 'ac-edit-control-unit-type-modal',
	CONTENT: 'ac-edit-control-unit-type-modal__content',
};

const AcEditControlUnitTypeModal = ({
	store: { ui, control_unit_types },
	data,
	submit,
	callback,
}) => {
	const [fields, setFields] = useState({
		name: data.type,
		series: data.series,
		description: data.description,
	});
	const [errors, setErrors] = useState({
		name: null,
		series: null,
		description: null,
	});
	const { hasErrors, handleInputChange, handleInputValidation } =
		useFormActions({
			fields,
			setFields,
			errors,
			setErrors,
		});

	const handleCancel = async (event) => {
		if (event && event.preventDefault) event.preventDefault();
		await ui.setValue(KEYS.MODAL, KEYS.VISIBLE, false);
	};

	const handleSubmit = (event) => {
		if (event && event.preventDefault) event.preventDefault();
		if (event && event.persist) event.persist();

		if (submit)
			submit(data.id, fields).then(() =>
				ui.setValue(KEYS.MODAL, KEYS.VISIBLE, false)
			);
	};

	const getContentClassNames = useMemo(() => {
		return clsx([_CLASSES.CONTENT]);
	}, []);

	const getStyleClassNames = useMemo(() => {
		return clsx([_CLASSES.MAIN]);
	}, []);

	const getTypeInputOptions = useMemo(() => {
		return {
			type: TYPES.TEXT,
			label: 'Type',
			name: 'name',
			value: fields.name,
			callback: handleInputChange,
			validation: handleInputValidation,
			focus: true,
		};
	}, [fields, fields.name]);

	const getSeriesInputOptions = useMemo(() => {
		return {
			type: TYPES.TEXT,
			label: 'Series',
			name: 'series',
			value: fields.series,
			callback: handleInputChange,
			validation: handleInputValidation,
		};
	}, [fields, fields.series]);

	const getDescriptionInputOptions = useMemo(() => {
		return {
			type: TYPES.TEXT,
			label: 'Description',
			name: 'description',
			value: fields.description,
			callback: handleInputChange,
			validation: handleInputValidation,
			required: false,
		};
	}, [fields, fields.description]);

	const getCancelButtonOptions = useMemo(() => {
		return {
			type: TYPES.BUTTON,
			theme: THEMES.OMEGA,
			variant: VARIANTS.TEXT,
			title: 'Cancel',
			callback: handleCancel,
		};
	});

	const getSubmitButtonOptions = useMemo(() => {
		return {
			type: TYPES.SUBMIT,
			theme: THEMES.ALPHA,
			disabled: hasErrors,
			title: 'Save changes',
			callback: handleSubmit,
		};
	}, [fields, errors, hasErrors]);

	return (
		<div className={getStyleClassNames}>
			<div className={getContentClassNames}>
				<form method={'post'} onSubmit={handleSubmit}>
					<AcContainer fluid>
						<AcRow>
							<AcColumn>
								<AcTextInput {...getTypeInputOptions} />
							</AcColumn>
						</AcRow>

						<AcRow>
							<AcColumn>
								<AcTextInput {...getSeriesInputOptions} />
							</AcColumn>
						</AcRow>

						<AcRow>
							<AcColumn>
								<AcTextInput {...getDescriptionInputOptions} />
							</AcColumn>
						</AcRow>

						<AcRow className={'h-margin-top-20'}>
							<AcColumn
								xxs={12}
								xs={7}
								sm={6}
								className={'h-text--align-left h-flex-v-align-center'}
							>
								<AcButton {...getCancelButtonOptions}>
									<span>Cancel</span>
								</AcButton>
							</AcColumn>

							<AcColumn
								xxs={12}
								xs={5}
								sm={6}
								className={'h-text--align-right'}
							>
								<AcButton {...getSubmitButtonOptions}>
									<span>Save changes</span>
								</AcButton>
							</AcColumn>
						</AcRow>
					</AcContainer>
				</form>
			</div>

			{control_unit_types.is_busy && <AcLoader loading={true} cover />}
		</div>
	);
};

export default withStore(observer(AcEditControlUnitTypeModal));
