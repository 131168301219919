// Imports => React
import React, { useEffect, useState, useMemo } from 'react';
import { withStore } from '@stores';
import { observer } from 'mobx-react-lite';
import clsx from 'clsx';

// Imports => Constants
import {
	KEYS,
	PERMISSIONS,
	THEMES,
	TYPES,
	VARIANTS,
	VISUALS,
} from '@constants';

// Imports => Utilities
import { AcIsSet } from '@utils';

// Imports => Hooks
import { usePermissions, useFormActions } from '@hooks';

// Imports => Molecules
import AcCheckPermissions from '@molecules/ac-check-permissions/ac-check-permissions.web';

// Imports => Components
import AcEditLocationMapWidget from '@components/ac-edit-location-map-widget/ac-edit-location-map-widget.web';

// Imports => Atoms
import { AcContainer, AcRow, AcColumn } from '@atoms/ac-grid';
import AcRichContent from '@atoms/ac-rich-content/ac-rich-content.web';
import AcTextInput from '@atoms/ac-text-input/ac-text-input.web';
import AcSelectBox from '@atoms/ac-select-box/ac-select-box.web';
import AcButton from '@atoms/ac-button/ac-button.web';
import AcLoader from '@atoms/ac-loader/ac-loader.web';

const _CLASSES = {
	MAIN: 'ac-edit-location-modal',
	CONTENT: 'ac-edit-location-modal__content',
};

const AcEditLocationModal = ({
	store: { projects, ui },
	data,
	submit,
	callback,
}) => {
	const { can, cannot } = usePermissions();

	const [location, setLocation] = useState(data.location);

	useEffect(() => {
		setLocation(data.location);
	}, [data.location]);

	const handleLocationUpdate = (value) => {
		if (value) setLocation(value);
	};

	const handleCancel = async (event) => {
		if (event && event.preventDefault) event.preventDefault();
		await ui.setValue(KEYS.MODAL, KEYS.VISIBLE, false);
	};

	const handleSubmit = (event) => {
		if (event && event.preventDefault) event.preventDefault();
		if (event && event.persist) event.persist();

		if (submit)
			submit(data.id, { ...data, location }).then(() =>
				ui.setValue(KEYS.MODAL, KEYS.VISIBLE, false)
			);
	};
	const getContentClassNames = useMemo(() => {
		return clsx([_CLASSES.CONTENT]);
	}, []);

	const getStyleClassNames = useMemo(() => {
		return clsx([_CLASSES.MAIN]);
	}, []);

	const getCancelButtonOptions = useMemo(() => {
		return {
			type: TYPES.BUTTON,
			theme: THEMES.OMEGA,
			variant: VARIANTS.TEXT,
			title: 'Cancel',
			callback: handleCancel,
		};
	});

	const getSubmitButtonOptions = useMemo(() => {
		return {
			type: TYPES.SUBMIT,
			theme: THEMES.ALPHA,
			disabled: !AcIsSet(location),
			title: 'Save changes',
			callback: handleSubmit,
		};
	}, [location]);

	return (
		<div className={getStyleClassNames}>
			<div className={getContentClassNames}>
				<form method={'post'} onSubmit={handleSubmit}>
					<AcContainer fluid>
						<AcRow>
							<AcColumn>
								<AcEditLocationMapWidget
									location={data.location}
									callback={handleLocationUpdate}
								/>
							</AcColumn>
						</AcRow>

						<AcRow className={'h-margin-top-20'}>
							<AcColumn
								xxs={12}
								xs={7}
								sm={6}
								className={'h-text--align-left h-flex-v-align-center'}
							>
								<AcButton {...getCancelButtonOptions}>
									<span>Cancel</span>
								</AcButton>
							</AcColumn>

							<AcColumn
								xxs={12}
								xs={5}
								sm={6}
								className={'h-text--align-right'}
							>
								<AcButton {...getSubmitButtonOptions}>
									<span>Save location</span>
								</AcButton>
							</AcColumn>
						</AcRow>
					</AcContainer>
				</form>
			</div>

			{projects.is_busy && <AcLoader loading={true} cover />}
		</div>
	);
};

export default withStore(observer(AcEditLocationModal));
