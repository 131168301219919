// Imports => React
import React, { useEffect, useState, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { withStore } from '@stores';
import { observer } from 'mobx-react-lite';
import clsx from 'clsx';

// Imports => Constants
import {
	DATETIME_FORMATS,
	KEYS,
	PERMISSIONS,
	ROUTES,
	THEMES,
	TYPES,
	VARIANTS,
	VISUALS,
} from '@constants';

// Imports => Utilities
import { AcIsSet, AcFormatDate } from '@utils';

// Imports => Hooks
import { usePermissions, useFormActions } from '@hooks';

// Imports => Molecules
import AcCheckPermissions from '@molecules/ac-check-permissions/ac-check-permissions.web';

// Imports => Atoms
import { AcContainer, AcRow, AcColumn } from '@atoms/ac-grid';
import AcRichContent from '@atoms/ac-rich-content/ac-rich-content.web';
import AcDatepickerInput from '@atoms/ac-datepicker-input/ac-datepicker-input.web';
import AcTextInput from '@atoms/ac-text-input/ac-text-input.web';
import AcCheckbox from '@atoms/ac-checkbox/ac-checkbox.web';
import AcButton from '@atoms/ac-button/ac-button.web';
import AcLoader from '@atoms/ac-loader/ac-loader.web';

const _CLASSES = {
	MAIN: 'ac-upload-pile-list-modal',
	CONTENT: 'ac-upload-pile-list-modal__content',
};

const AcEditSoftwareUpdateModal = ({
	store: { ui, control_unit_types },
	instance = null,
	control_unit_type,
	data,
	submit,
	callback,
}) => {
	const navigate = useNavigate();
	const { can, cannot } = usePermissions();

	let raw_fields = {
		equipment_type_id: (control_unit_type && control_unit_type.id) || '',
		name: (data && data.name) || '',
		released_at: (data && data.released_at) || new Date(),
		release_notes: (data && data.release_notes) || '',
		is_available: (data && data.is_available) || false,
	};
	let raw_errors = {
		equipment_type_id: null,
		name: null,
		released_at: null,
		release_notes: null,
		is_available: null,
	};

	const [fields, setFields] = useState(raw_fields);
	const [errors, setErrors] = useState(raw_errors);

	const { hasErrors, handleInputChange, handleInputValidation } =
		useFormActions({
			fields,
			setFields,
			errors,
			setErrors,
		});

	const handleCancel = async (event) => {
		if (event && event.preventDefault) event.preventDefault();
		await ui.setValue(KEYS.MODAL, KEYS.VISIBLE, false);
	};

	const handleSubmit = (event) => {
		if (event && event.preventDefault) event.preventDefault();
		if (event && event.persist) event.persist();

		if (submit && !hasErrors) {
			const released_at = AcFormatDate(
				fields.released_at,
				null,
				DATETIME_FORMATS.RAW_DATE
			);

			const release_notes =
				(fields.release_notes &&
					fields.release_notes.replace(/\n\r?/g, '<br />')) ||
				'';

			const output = {
				equipment_type_id: control_unit_type.id,
				name: fields.name,
				is_available: fields.is_available ? 1 : 0,
				release_notes,
				released_at,
			};

			submit(control_unit_type.id, data.id, output).then((response) => {
				ui.setValue(KEYS.MODAL, KEYS.VISIBLE, false);
			});
		}
	};

	const getContentClassNames = useMemo(() => {
		return clsx([_CLASSES.CONTENT]);
	}, []);

	const getStyleClassNames = useMemo(() => {
		return clsx([_CLASSES.MAIN]);
	}, []);

	const getUpdateFileInputOptions = useMemo(() => {
		return {
			type: TYPES.TEXT,
			label: 'File',
			name: 'file',
			value: data.file,
			disabled: true,
			callback: null,
		};
	}, [instance, data]);

	const getVersionNumberInputOptions = useMemo(() => {
		return {
			type: TYPES.TEXT,
			label: 'Version number',
			name: 'name',
			value: fields.name,
			callback: handleInputChange,
		};
	}, [fields, fields.name]);

	const getReleaseNotesInputOptions = useMemo(() => {
		const value = fields.release_notes.replace(/<br\s*[\/]?>/gi, '\n');
		return {
			type: TYPES.TEXTAREA,
			label: 'Release notes',
			name: 'release_notes',
			value,
			callback: handleInputChange,
			className: 'h-margin-bottom-10',
		};
	}, [fields, fields.release_notes]);

	const getReleasedAtInputOptions = useMemo(() => {
		const date = new Date(fields.released_at);

		return {
			type: TYPES.DATE,
			label: 'Released at',
			placeholder: 'dd-mm-yyyy',
			name: 'released_at',
			value: date,
			range: 'start',
			callback: handleInputChange,
			validation: handleInputValidation,
			required: false,
		};
	}, [fields, fields.released_at]);

	const getAvailableCheckboxOptions = useMemo(() => {
		return {
			type: TYPES.BOOLEAN,
			name: 'is_available',
			value: true,
			callback: handleInputChange,
			validation: handleInputValidation,
			checked: fields.is_available,
		};
	}, [fields, fields.is_available]);

	const getCancelButtonOptions = useMemo(() => {
		return {
			type: TYPES.BUTTON,
			theme: THEMES.OMEGA,
			variant: VARIANTS.TEXT,
			title: 'Cancel',
			callback: handleCancel,
		};
	});

	const getSubmitButtonOptions = useMemo(() => {
		return {
			type: TYPES.SUBMIT,
			theme: THEMES.ALPHA,
			disabled: hasErrors,
			title: 'Save',
			disabled: hasErrors || control_unit_types.is_busy,
			callback: handleSubmit,
		};
	}, [data, fields, hasErrors, control_unit_types]);

	return (
		<div className={getStyleClassNames}>
			<div className={getContentClassNames}>
				<form
					method={'post'}
					onSubmit={handleSubmit}
					encType={'multipart/form-data'}
				>
					<AcContainer fluid>
						<AcRow>
							<AcColumn>
								<AcTextInput {...getUpdateFileInputOptions} />
							</AcColumn>
						</AcRow>

						<AcRow>
							<AcColumn>
								<AcTextInput {...getVersionNumberInputOptions} />
							</AcColumn>
						</AcRow>

						<AcRow className={'h-margin-top-0'}>
							<AcColumn xs={12} sm={6}>
								<AcDatepickerInput {...getReleasedAtInputOptions} />
							</AcColumn>
						</AcRow>

						<AcRow>
							<AcColumn>
								<AcTextInput {...getReleaseNotesInputOptions} />
							</AcColumn>
						</AcRow>

						<AcRow className={'h-margin-top-0'}>
							<AcColumn>
								<AcCheckbox {...getAvailableCheckboxOptions}>
									<span
										dangerouslySetInnerHTML={{
											__html: 'Update is available and ready to use',
										}}
									/>
								</AcCheckbox>
							</AcColumn>
						</AcRow>

						<AcRow className={'h-margin-top-20'}>
							<AcColumn
								xxs={12}
								xs={7}
								sm={6}
								className={'h-text--align-left h-flex-v-align-center'}
							>
								<AcButton {...getCancelButtonOptions}>
									<span>Cancel</span>
								</AcButton>
							</AcColumn>

							<AcColumn
								xxs={12}
								xs={5}
								sm={6}
								className={'h-text--align-right'}
							>
								<AcButton {...getSubmitButtonOptions}>
									<span>Save changes</span>
								</AcButton>
							</AcColumn>
						</AcRow>
					</AcContainer>
				</form>
			</div>

			{control_unit_types && control_unit_types.is_busy && (
				<AcLoader loading={true} cover />
			)}
		</div>
	);
};

export default withStore(observer(AcEditSoftwareUpdateModal));
